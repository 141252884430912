<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        A sample of
        <number-value :value="mass" unit="\text{g}" />
        of plastic polystyrene was dissolved in enough toluene to form 100 mL of solution. The
        osmotic pressure of this solution was found to be
        <number-value :value="P1" unit="\text{kPa}" />
        at
        <stemble-latex content="$25^\circ\text{C.}$" />
      </p>

      <p class="mb-3">
        a) Calculate the molarity of the solution based on the given osmotic pressure.
      </p>

      <calculation-input
        v-model="inputs.molarity"
        class="mb-5"
        prepend-text="$\text{Molarity:}$"
        append-text="$\text{mol/L}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) Based on your calculated molarity, how many moles of polystyrene are in the solution?
      </p>

      <calculation-input
        v-model="inputs.mol"
        class="mb-5"
        prepend-text="$\text{Amount}:$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">c) Determine the molar mass of the polystyrene.</p>

      <calculation-input
        v-model="inputs.molarMass"
        prepend-text="$\text{Molar Mass:}$"
        append-text="$\text{g/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question416',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molarity: null,
        mol: null,
        molarMass: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
    P1() {
      return this.taskState.getValueBySymbol('P1').content;
    },
  },
};
</script>
